<template>
  <v-sheet class="card-size">
    <b-card
      footer-tag="footer"
      class="mb-4 card-shadow"
      border-variant="allgood"
      tabindex="1"
    >
      <template v-slot:header >

        <div class="nav-link tab-input">
          <v-text-field
            label="Name"
            v-model="newContainerData.name"
            class="pa-3"
            style="max-width: 400px; margin: auto;"
          ></v-text-field>
          <div class="input-right" @click.stop="addNewContainer">
            <Icon
              iconName="check-circle"
              size="8px"
              color="green"
            />
          </div>
        </div>
      </template>
      <div style="min-height: 40px"></div>
      <template>

      </template>
    </b-card>
  </v-sheet>
</template>

<script>
import { mapActions } from 'vuex'
import Icon from '@/components/elements/Icon'
import { cloneDeep } from 'lodash'
import TreeModel from 'tree-model-improved'
const uuidv4 = require('uuid').v4
const treeModel = new TreeModel({
  modelComparatorFn: (a, b) => a.name?.toLowerCase() > b.name?.toLowerCase()
})

export default {
  name: 'NewCard',
  components: { Icon },
  data () {
    return {
      newContainerData: {
        id: uuidv4(),
        name: '',
        devices: [],
        children: [],
        metadata: {
          name: '',
          status: '',
          text: ''
        }
      }
    }
  },
  props: {
    currNavs: Array,
    currID: String,
    getContainers: Object,
    getMyIds: Object,
    toggleEditMode: Function
  },
  methods: {
    ...mapActions({
      updateContainer: 'containers/updateContainer',
      setSelectedContainer: 'containers/setSelectedContainer'
    }),
    addNewContainer () {
      let currTree = treeModel.parse(cloneDeep(this.getContainers))
      let currNode = currTree.first(node => {
        return node.model.id === this.currNavs[0].id // grab sibling level of new Container
      })
      this.newContainerData.customerId = this.getMyIds?.CUSTOMER?.[0] ?? 'DEFAULT'
      this.newContainerData.fulfillerId = this.getMyIds?.FULFILLER?.[0] ?? 'DEFAULT'
      currNode.addChild(treeModel.parse(this.newContainerData))

      this.updateContainer({ container: currTree.model, msgType: 'container_create', updatedObj: this.newContainerData })
      this.toggleEditMode()
    }
  }
}
</script>
